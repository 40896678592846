<template>
    <LayoutEtapas>

        <div class='formgrid grid p-fluid'>
            <div class='field col-3'>
                <BDropdown
                    id='idAbrangencia'
                    v-model='idAbrangencia'
                    :disabled='desabilita'
                    :itens='listaAbrangencia'
                    :mensagemErro="v$.idAbrangencia.$silentErrors[0]?.$message || ''"
                    :temErro='v$.idAbrangencia.$error'
                    filter
                    label='Abrangência'
                    required
                    @blur='v$.idAbrangencia.$touch()'
                />
            </div>
            <!-- <div class='field col-6'>
                <BDropdown
                    id='idModalidadeApoio'
                    v-model='idModalidadeApoio'
                    :disabled='desabilita'
                    :itens='listaModalidadeApoio'
                    filter
                    label='Modalidade de Apoio'
                />
            </div> -->
            <div class='field col-3'>
                <span v-tooltip="'<strong>E-mail</strong> da pessoa, na instituição <strong>parceira, responsável pela</strong> área de <strong>Proteção de Dados</strong>.'">
                <BInput v-model='emailRepresentanteLgpd' :desabilita='desabilita'
                        :mensagemErro="v$.emailRepresentanteLgpd.$silentErrors[0]?.$message || ''"
                        :temErro='v$.emailRepresentanteLgpd.$error'
                        label='Email do rep. LGPD'
                        name='emailRepresentanteLgpd'
                        required
                        @blur='v$.emailRepresentanteLgpd.$touch()' />
                </span>
            </div>
            <div class='field col-3'>
                <span v-tooltip="'<strong>Telefone</strong> da pessoa, na instituição <strong>parceira, responsável pela</strong> área de <strong>Proteção de Dados</strong>.'">
                <BInputMask v-model='telefoneRepresentanteLgpd' :desabilita='desabilita'
                            :mensagemErro="v$.telefoneRepresentanteLgpd.$silentErrors[0]?.$message || ''"
                            :temErro='v$.telefoneRepresentanteLgpd.$error'
                            label='Telefone do rep. LGPD'
                            mask='(99) 99999-9999'
                            name='telefoneRepresentanteLgpd'
                            required unmask
                            @blur='v$.telefoneRepresentanteLgpd.$touch()' />
                </span>
            </div>
            <!-- <div class='field col-3'>
                <BInput v-model='local' :desabilita='desabilita'
                        :mensagemErro="v$.local.$silentErrors[0]?.$message || ''" :temErro='v$.local.$error'
                        label='Local'
                        name='local'
                        required
                        @blur='v$.local.$touch()' />
            </div>
            <div class='field col-3'>
                <BCalendar
                    v-model='dataAcordo'
                    :desabilita='desabilita'
                    :mensagemErro="v$.dataAcordo.$silentErrors[0]?.$message || ''"
                    :temErro='v$.dataAcordo.$error'
                    label='Data do Acordo'
                    required
                    @blur='v$.dataAcordo.$touch()'
                />
            </div> -->
        </div>

        <div class='flex flex-wrap gap-3'>
            <div class='flex-1'></div>
            <Button style='margin-right: 10px' @click='$router.back()'>Voltar</Button>
            <Button :disabled='v$.$invalid' @click='onSubmit()'>Continuar</Button>
        </div>
    </LayoutEtapas>
</template>
<script>
import LayoutEtapas from './LayoutEtapas.vue';
import Services from '../../../service';
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';

export default {
    components: {
        LayoutEtapas,
    },
    setup() {
        return {
            v$: useVuelidate({}),
        };
    },
    validations() {
        return {
            idAbrangencia: {
                required: helpers.withMessage('Campo obrigatório', required),
            },
            // idModalidadeApoio: {},
            emailRepresentanteLgpd: {
                required: helpers.withMessage('Campo obrigatório', required),
                email: helpers.withMessage('Não é um email válido', email),
            },
            telefoneRepresentanteLgpd: { required: helpers.withMessage('Campo obrigatório', required) },
            // local: { required: helpers.withMessage('Campo obrigatório', required) },
            // dataAcordo: {
            //     required: helpers.withMessage('Campo obrigatório', required),
            //     dataValida: helpers.withMessage('Não é uma data válida', (val) => this.$moment(val, 'DD/MM/YYYY').isValid()),
            // },
        };
    },
    data() {
        return {
            desabilita: false,
            listaAbrangencia: [
                {
                    id: 1,
                    label: 'Local',
                },
                {
                    id: 2,
                    label: 'Regional',
                },
                {
                    id: 3,
                    label: 'Estadual',
                },
                {
                    id: 4,
                    label: 'Aberta',
                },
            ],
            listaModalidadeApoio: [],
            idAbrangencia: '',
            // idModalidadeApoio: '',
            emailRepresentanteLgpd: '',
            telefoneRepresentanteLgpd: '',
            // local: '',
            // dataAcordo: '',
        };
    },
    methods: {
        async carregarDados() {
            const id = this.$route.params.id;
            this.$store.dispatch('addRequest');
            Services.SelectByIdParcerias(id)
                .then((response) => {
                    if (response && response.success && response.data) {
                        this.emailRepresentanteLgpd = response.data.emailRepresentanteLgpd;
                        this.telefoneRepresentanteLgpd = response.data.telefoneRepresentanteLgpd;
                        // this.local = response.data.local;
                        // this.dataAcordo = response.data.dataAcordoFormatada;
                        this.idAbrangencia = response.data.idAbrangencia;
                        // this.idModalidadeApoio = response.data.idModalidadeApoio;
                        this.desabilita = response.data.status !== 0 && response.data.status !== 1;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
        goNextPage() {
            const id = this.$route.params.id;
            this.$router.push({
                name: 'MapaParceiros_Parceria_Formulario_ValoresPerfis',
                params: {
                    id: id,
                },
            });
        },
        async onSubmit() {
            const id = this.$route.params.id;
            this.$store.dispatch('addRequest');
            const data = {
                emailRepresentanteLgpd: this.emailRepresentanteLgpd,
                telefoneRepresentanteLgpd: this.telefoneRepresentanteLgpd,
                // local: this.local,
                // dataAcordo: this.$moment(this.dataAcordo, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                idAbrangencia: this.idAbrangencia
                // idModalidadeApoio: this.idModalidadeApoio
            };
            Services.AlterarAcordoDeParceriaDadosDoContrato(id, data)
                .then((response) => {
                    if (response && response.success && response.data) {
                        this.goNextPage();
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
        // carregaComboModalidadeApoio() {
        //     this.$store.dispatch('addRequest');
        //     Services.SelectAllModalidadesApoio()
        //         .then((response) => {
        //             if (response && response.success) {
        //                 this.listaModalidadeApoio = response.data.map((item) => {
        //                     return {
        //                         id: item.id,
        //                         label: item.descricao,
        //                     };
        //                 });
        //             } else {
        //                 this.listaModalidadeApoio = [];
        //             }
        //         })
        //         .finally(() => {
        //             this.$store.dispatch('removeRequest');
        //         });
        // },
    },
    mounted() {
        if (!this.$route.params.id) {
            this.$router.replace({
                name: 'MapaParceiros_Parceria_Formulario_DadosGerais',
            });
        } else {
            this.carregarDados();
        }
        // this.carregaComboModalidadeApoio();
    },
};
</script>